/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Location } from "@reach/router"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer>
        <p>© 2019–{new Date().getFullYear()} <Link to="/">Mr. Rubber Brasil</Link>. Todos os direitos reservados.</p>
        <p>Veja o nosso <Link to="/">código de conduta</Link> (disponível em breve).</p>
        <p>Siga-nos no:
          {' '}
          <a href="https://twitter.com/mrrubberbr" rel="noreferrer" target="_blank">Twitter</a>
          {' • '}
          <a href="https://instagram.com/mrrubberbr" rel="noreferrer" target="_blank">Instagram</a>
          {' • '}
          <a href="https://www.facebook.com/mrrubberbr" rel="noreferrer" target="_blank">Facebook</a>
        </p>
      </footer>
    </>
  )
}

export default Layout
