import { Link } from "gatsby"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/">{siteTitle}</Link>
    </h1>
    <nav>
      <ul>
        <li><Link to="/evento" activeClassName="active">Evento</Link></li>
        <li><Link to="/concurso" activeClassName="active">Concurso</Link></li>
        <li><Link to="/historico" activeClassName="active">Histórico</Link></li>
        <li><Link to="/sobre" activeClassName="active">Sobre</Link></li>
        <li><Link to="/contato" activeClassName="active">Contato</Link></li>
      </ul>
    </nav>
  </header>
)

export default Header
